import React from "react";
import Footer from "../../components/Footer";
import Team from "./TeamView";

export function TeamContainer() {
  return (
    <div>
      <Team title="Equipe"/>
      <Footer/>
    </div>
  );
}
